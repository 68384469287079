body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.main-container-section {
  padding: 16px 16px 0 16px !important;
}

.side-menu-list {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.p-0 {
  padding: 0px !important;
}

.p-18 {
  padding: 8px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-24 {
  padding: 24px !important;
}

.pt-50 {
  padding-top: 50px !important;
}
.mt-50 {
  margin-top: 50px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.my-2 {
  margin: 0.5rem 0 !important;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.h-220 {
  height: 220px !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.react-select > div:nth-child(3) {
  z-index: 99999;
}

.react-select > div:nth-child(2) {
  z-index: 9;
}

.custom-react-select-form {
  position: relative;
}
.position-relative {
  position: relative !important;
}

.custom-react-select-label {
  position: absolute !important;
  margin-bottom: 17px !important;
  z-index: 10 !important;
  background-color: white !important;
  margin-top: -18px !important;
  font-size: 12px !important;
}

.react-select-label {
  margin-top: -20px !important;
  margin-left: 0px !important;
}

.date-label {
  margin-top: -18px !important;
  margin-left: 0px !important;
}

.react-select-overflow-top > div:nth-child(3) {
  bottom: 100% !important;
  top: auto !important;
}

.count-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  text-align: center;
}

.pointer {
  cursor: pointer !important;
}

.dashed-border {
  border: 3px dashed #d5d5d5;
  border-radius: 8px;
}

.align-items-center {
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-1 {
  flex: 1;
}

.custom-table tr td {
  border-bottom: none !important;
  /* border-left: 1px solid #000;
  border-right: 1px solid #000; */
}

.custom-table {
  /* border: 1px solid #000 !important; */
}

.attention_table_odd {
  background-color: transparent !important;
}
.attention_table_even {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.text-center {
  text-align: center !important;
}

.select-label {
  background-color: #fff;
  padding: 0px 5px !important;
}

.px-16 {
  padding: 0 16px !important;
}

.py-16 {
  padding: 16px 0 !important;
}

.detailed-timesheet-table {
  height: calc(100vh - 422px);
}

.expense-detailed-timesheet-table {
  height: calc(100vh - 300px);
}

.users-filter {
  width: 280px;
}

.w-100px {
  width: 100px !important;
}

.text-right {
  text-align: right;
}

.users-table-container {
  max-height: calc(100vh - 308px) !important;
  position: relative !important;
}
.resources-table-container {
  max-height: calc(100vh - 250px) !important;
  position: relative !important;
}

.timesheets-table-container-with-employee-details {
  max-height: calc(100vh - 140px) !important;
  /* max-height: calc(100vh - 235px) !important; */
  position: relative !important;
}

.timesheets-table-container {
  max-height: calc(100vh - 235px) !important;
  position: relative !important;
}

.notes-table-container {
  max-height: calc(100vh - 245px) !important;
  position: relative !important;
}

.time-approved-by-table-container {
  max-height: calc(100vh - 187px) !important;
}

.time-category-table-container {
  max-height: calc(100vh - 187px) !important;
}

.professional-level-table-container {
  max-height: calc(100vh - 187px) !important;
}

.keyword-table-container {
  max-height: calc(100vh - 187px) !important;
}

.review-attention-table-container {
  max-height: calc(100vh - 217px) !important;
}

.timesheets-details-table-container {
  max-height: calc(100vh - 392px) !important;
  /* max-height: calc(100vh - 231px) !important; */
}

.report-right-side-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 134px);
}

.report-details-container {
  /* max-height: calc(100vh - 290px) !important; */
  /* max-height: calc(100vh - 246px) !important; */
  /* max-height: calc(100vh - 134px) !important; */
  overflow: auto;
  padding-left: 4px;
  padding-right: 8px;
  flex: 1;
}

.max-w-172px {
  max-width: 172px !important;
}

.custom-chip {
  margin-right: 4px;
  margin-bottom: 4px;
}

.border {
  border: 1px solid #000 !important;
}

.timesheet-filters-height-with-employee-details {
  max-height: calc(100vh - 278px) !important;
  /* max-height: calc(100vh - 135px) !important; */
  overflow: auto !important;
  position: relative;
  padding: 0 8px 0 0;
}

.timesheet-filters-height {
  max-height: calc(100vh - 135px) !important;
  overflow: auto !important;
  position: relative;
  padding: 0 8px 0 0;
}

.time-entries-filters-height {
  max-height: calc(100vh - 125px) !important;
  overflow: auto !important;
  position: relative;
}

.expense-view-filters-height {
  max-height: calc(100vh - 235px) !important;
  overflow: auto !important;
  position: relative;
}

.dashboard-filters-height {
  max-height: calc(100vh - 130px) !important;
  overflow: auto !important;
  position: relative;
}
.non-dashboard-filters-height {
  max-height: calc(100vh - 92px) !important;
  overflow: auto !important;
  position: relative;
}

.pl-16 {
  padding-left: 16px !important;
}

.non-dashboard-filters-height::-webkit-scrollbar-track,
.timesheet-filters-height::-webkit-scrollbar-track,
.expense-view-filters-height::-webkit-scrollbar-track,
.time-entries-filters-height::-webkit-scrollbar-track,
.dashboard-filters-height ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.non-dashboard-filters-height::-webkit-scrollbar,
.timesheet-filters-height::-webkit-scrollbar,
.expense-view-filters-height::-webkit-scrollbar,
.time-entries-filters-height::-webkit-scrollbar,
.dashboard-filters-height::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.non-dashboard-filters-height::-webkit-scrollbar-thumb,
.timesheet-filters-height::-webkit-scrollbar-thumb,
.expense-view-filters-height::-webkit-scrollbar-thumb,
.time-entries-filters-height::-webkit-scrollbar-thumb,
.dashboard-filters-height::-webkit-scrollbar-thumb {
  background-color: #616161e6;
}

.flex-no-wrap {
  flex-wrap: nowrap !important;
}

.head-header {
  position: sticky;
  min-width: 130px;
  left: -1px;
  top: auto;
  z-index: 99;
  background-color: #cccccc !important;
  /*only relevant for first row*/
  /*compensate for top border*/
}

.head-column {
  position: sticky;
  width: 160px;
  left: -1px;
  top: auto;
  background-color: #fbfbfb;
  /*only relevant for first row*/
  /*compensate for top border*/
}

.pt-8 {
  padding-top: 8px !important;
}
.ml-8 {
  margin-left: 8px !important;
}

.custom-side-menu a {
  white-space: initial !important;
}

.nested-custom-side-menu a {
  padding-left: 32px !important;
}

.form-label {
  position: absolute;
  left: 8px;
  top: -10px;
  background: #fff;
  z-index: 9999;
  font-size: 12px;
  padding: 0 4px;
}
.time-entry-description {
  min-width: 250px;
}
.menu-collapsed .custom-side-menu a div span {
  overflow: hidden;
  white-space: nowrap;
}
.react-date-picker {
  width: 100%;
}
.react-date-picker__calendar {
  z-index: 99 !important;
}
.react-date-picker__inputGroup {
  pointer-events: none !important;
}
.react-date-picker__clear-button {
  display: none;
}
.calendar-with-clear .react-date-picker__clear-button {
  display: block;
}
.react-date-picker__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px;
  padding: 4px !important;
}
.react-calendar__navigation button {
  min-width: 32px !important;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 3px !important;
}
.react-calendar__tile {
  padding: 12px 4px !important;
}
.react-calendar__year-view__months button {
  flex-basis: 50% !important;
  max-width: 50% !important;
}

.min-width-600 .MuiDialog-paper {
  min-width: 600px;
}

.disapproved-section {
  margin-top: -12px !important;
  padding-left: 12px !important;
}
.only-disapproved-section {
  margin-top: -6px !important;
  padding-left: 12px !important;
}
.selected-split-entry {
  background: #e3e7fd !important;
  color: #fff !important;
}
.text-danger {
  color: #e70404 !important;
}
.react-select-field-error > div:nth-child(1),
.react-date-error > div:nth-child(1) {
  border: 1px solid #e70404 !important;
}
.custom-error-field {
  border: 1px solid #e70404 !important;
  border-radius: 4px;
}
.resource-field-value {
  margin-left: 4px !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 8px !important;
}
.professional-characterization-container {
  margin-top: 8px !important;
}
.secondary-loading {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translateY(-40%);
  transform: translateY(-40%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 999;
  padding: 16px;
}
.mt--8 {
  margin-top: -8px;
}
.toggle-filters-timesheet-container-with-employee-details {
  max-height: calc(100vh - 278px) !important;
  height: calc(100vh - 278px) !important;
  /* max-height: calc(100vh - 135px) !important;
  height: calc(100vh - 135px) !important; */
}
.toggle-filters-timesheet-container {
  max-height: calc(100vh - 135px) !important;
  height: calc(100vh - 135px) !important;
}
.toggle-filters-timesheet-entries-container {
  max-height: calc(100vh - 125px) !important;
  height: calc(100vh - 125px) !important;
}
.attention-details-view {
  max-height: calc(100vh - 370px) !important;
  /* height: calc(100vh - 370px) !important; */
}
.toggle-filters-report-container {
  max-height: calc(100vh - 135px) !important;
  height: calc(100vh - 135px) !important;
}
.toggle-filters-non-dashboard-container {
  max-height: calc(100vh - 92px) !important;
  height: calc(100vh - 92px) !important;
  margin-top: -8px !important;
}
.non-dashboard-main-container {
  overflow: auto;
  padding: 16px 16px 16px 8px;
}
.protocol-type-container {
  max-height: calc(100vh - 575px) !important;
  overflow-y: auto;
}
.cmo-table-column-width {
  min-width: 265px !important;
}
.cmo-table-hours-column-width {
  min-width: 181px !important;
}
.report-details-table-column-width {
  min-width: 201px !important;
}
.time-detail-table-row td {
  font-weight: 600;
}
.view-timesheet-filters {
  padding-right: 8px;
}
.fixed-header-column {
  position: sticky;
  top: 0;
  z-index: 99;
}
.firm-service-header-fixed-column-2 {
  position: sticky;
  /* top: 60px; */
  z-index: 99;
}
.fixed-header-column:nth-child(1),
.firm-service-header-fixed-column-2:nth-child(1) {
  z-index: 100 !important;
}
.review-attention-dialog > div > div {
  max-width: 1340px !important;
}
.un-auth-pages {
  height: 100vh !important;
  overflow-x: hidden !important;
}
.cmo-text-dialog > div > div {
  min-width: 600px !important;
}
.all-selected-filter-dialog > div > div {
  min-width: 900px !important;
}
.advanced-filter-container {
  pointer-events: none;
  opacity: 0.5;
}
.sql-query-chip {
  height: auto !important;
  align-items: flex-start !important;
}
.sql-query-chip > span {
  display: block !important;
  white-space: normal !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.sql-query-chip > svg {
  margin-top: 5px !important;
  margin-bottom: 4px !important;
}
.report-react-select > div > div:nth-child(3) {
  z-index: 11 !important;
}
.custom-date-range-picker > div:first-child {
  width: 140px !important;
}
.custom-date-range-picker > div:last-child > div:first-child {
  display: none !important;
}
.custom-date-range .rdrDateDisplayWrapper {
  display: none !important;
}
.date-picker-popover .MuiPaper-root {
  display: flex !important;
}
.custom-date-range-picker .rdrMonthPicker select,
.custom-date-range .rdrMonthPicker select {
  text-align: left !important;
}
.login-text {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.dashboard-professional-level-chart-container {
  height: 450px;
  width: 100%;
  margin-bottom: 24px !important;
}
.dashboard-professional-switch-label {
  cursor: pointer;
  font-size: 16px;
}
.dashboard-count-entry {
  min-width: 160px !important;
}
.pie-chart-label {
  width: 18px;
  height: 18px;
  margin: 0 4px 0 8px;
}
.case-header-item-case-id{
  min-width: 240px;
}
.case-header-item{
  min-width: 150px;
}

@media screen and (min-width: 1200px) {
  .fs-50 {
    font-size: 50px !important;
  }
}

.drawer-dialog-body {
  overflow: auto;
  margin-top: 50px;
}

@media (max-width: 575.98px) {
  .mini-drawer > div:nth-child(3) {
    width: 100% !important;
  }
  .small-drawer > div:nth-child(3) {
    width: 100% !important;
  }
  .medium-drawer > div:nth-child(3) {
    width: 100% !important;
  }
  .large-drawer > div:nth-child(3) {
    width: 100% !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .mini-drawer > div:nth-child(3) {
    width: 65% !important;
  }
  .small-drawer > div:nth-child(3) {
    width: 85% !important;
  }
  .medium-drawer > div:nth-child(3) {
    width: 90% !important;
  }
  .large-drawer > div:nth-child(3) {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .mini-drawer > div:nth-child(3) {
    width: 50% !important;
  }
  .small-drawer > div:nth-child(3) {
    width: 65% !important;
  }
  .medium-drawer > div:nth-child(3) {
    width: 75% !important;
  }
  .large-drawer > div:nth-child(3) {
    width: 85% !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .mini-drawer > div:nth-child(3) {
    width: 40% !important;
  }
  .small-drawer > div:nth-child(3) {
    width: 55% !important;
  }
  .medium-drawer > div:nth-child(3) {
    width: 65% !important;
  }
  .large-drawer > div:nth-child(3) {
    width: 75% !important;
  }
}

/*  Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
  .mini-drawer > div:nth-child(3) {
    width: 30% !important;
  }
  .small-drawer > div:nth-child(3) {
    width: 45% !important;
  }
  .medium-drawer > div:nth-child(3) {
    width: 55% !important;
  }
  .large-drawer > div:nth-child(3) {
    width: 75% !important;
  }
}

/** Mozilla Firefox */
@-moz-document url-prefix() {
  .moz-table-pb {
    padding-bottom: 8px;
  }
}
.notifications-table-container {
  max-height: calc(100vh - 150px) !important;
}
