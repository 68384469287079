.plaintiff-name-cell{
    min-width: 200px;
}
.plaintiff-name-suffix-cell{
    min-width: 170px;
}
.cell-150px{
    min-width: 150px;
}
.cell-120px{
    min-width: 120px;
}
.case-summary-firm-table{
    max-height: 300px;
    padding: 8px;
}